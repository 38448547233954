<template>
  <LibraryCategoryCard>
    <div class="category-quote-card d-flex flex-column justify-space-between">
      <div class="quote-text-wrap">
        <a class="category-quote-text" :href="linkToQuote">
          {{ quote.quote }}
        </a>
      </div>
      <div class="pt-2">
        <AuthorLinkControl
          v-if="isMounted"
          class="category-quote-author-link"
          :publication="publication"
          is-link
        />
      </div>
    </div>
  </LibraryCategoryCard>
</template>

<script>
import LibraryCategoryCard from '@/components/views/CategoryView/LibraryCategoryCard/LibraryCategoryCard.vue';
import AuthorLinkControl from '@/components/controls/AuthorLinkControl/AuthorLinkControl.vue';
export default {
  name: 'CategoryQuoteCard',
  components: { LibraryCategoryCard, AuthorLinkControl },
  props: {
    quote: {
      type: Object,
      required: false
    }
  },
  data() {
    return {
      isMounted: false
    };
  },
  computed: {
    publication() {
      return {
        author: this.quote?.author
      };
    },

    linkToQuote() {
      if (process.client) {
        return this.quote?.link || '';
      }
      return '';
    }
  },
  mounted() {
    this.isMounted = true;
  },
  methods: {}
};
</script>

<style lang="less">
.quote-text-wrap {
  display: flex;
  gap: 16px;

  &:after {
    content: '”';
    display: block;
    font-family: 'Times New Roman', Times, serif;
    font-size: 150px;
    line-height: 50px;
    margin-top: 28px;
    color: #e0ddd5;
    height: 20px;
  }
}

.library-category-card {
  &:has(.category-quote-card) {
    flex-direction: column;

    .category-quote-card {
      flex-grow: 1;
    }
  }

  .quote-text-wrap a {
    color: #0f172a;
  }
}

.category-quote-card {
  .category-quote-text {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #0f172a;
    white-space: normal;
    text-decoration: none;
    background: none;
    outline: none;
    border: none;
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    flex-grow: 1;

    &:hover {
      text-decoration: underline;
    }
  }

  .category-quote-author-link {
    font-size: 14px;
    color: var(--primary-color);
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}
</style>
