<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    :aria-labelledby="iconName"
    :fill="iconColor"
    viewBox="0 0 24 24"
    role="presentation"
  >
    <slot>
      <use
        v-if="stripeUrl && iconName"
        :href="stripeUrl + '#' + iconName"
        :xlink:href="stripeUrl + '#' + iconName"
      />
    </slot>
  </svg>
</template>

<script>
export default {
  props: {
    iconName: {
      type: String,
      default: 'box'
    },
    width: {
      type: [Number, String],
      default: 20
    },
    height: {
      type: [Number, String],
      default: 20
    },
    iconColor: {
      type: String,
      default: 'currentColor'
    },
    stripeUrl: {
      type: String
    }
  }
};
</script>

<style lang="less" src="./BaseIcon.less"></style>
