<template>
  <div class="category-author-info d-flex flex-wrap align-start flex-grow-1">
    <div
      v-for="(authorObj, index) in authors"
      :key="`${authorObj.author}_${index}`"
      class="d-flex"
    >
      <CategoryAuthorCard
        :author="authorObj.author"
        :author-slug="authorObj.authorSlug"
        :number="authorObj.number"
        :card-color="cardColor"
      />
    </div>
  </div>
</template>

<script>
import CategoryAuthorCard from '@/components/views/CategoryView/CategoryAuthorInfo/CategoryAuthorCard.vue';

export default {
  name: 'CategoryAuthorInfo',
  components: { CategoryAuthorCard },
  props: {
    category: {
      type: String,
      required: false
    },
    cardColor: String,
    maxNumber: Number
  },
  data() {
    return {
      isMounted: false
    };
  },
  computed: {
    categoryAuthors() {
      const currentLanguage = this.$store.getters[
        'ContextStore/currentLanguageGetter'
      ];
      let authors = [];
      if (!this.isMounted) {
        authors = this.$store.getters[
          'LibraryStore/getServerSideCategoryAuthorList'
        ];
      } else {
        authors = this.$store.getters['LibraryStore/getCategoryAuthors']({
          category: this.category,
          lang: currentLanguage
        });
      }
      return authors;
    },
    authors() {
      let authors = this.categoryAuthors || [];
      if (this.maxNumber) {
        authors = (authors || []).slice(0, this.maxNumber);
        const notDisplayedAuthors = (this.categoryAuthors || []).slice(
          this.maxNumber
        );
        if (notDisplayedAuthors.length) {
          authors.push({
            author: '',
            number: notDisplayedAuthors.length
          });
        }
      }
      return authors;
    }
  },
  watch: {
    authors() {
      this.emitNumberAuthors();
    }
  },
  mounted() {
    this.isMounted = true;
    this.emitNumberAuthors();
  },
  methods: {
    emitNumberAuthors() {
      this.$emit('calcAuthors', this.authors?.length || 0);
    }
  }
};
</script>

<style lang="less" src="./CategoryAuthorInfo.less"></style>
