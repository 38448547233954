<template>
  <div class="library-category-card d-flex pa-4">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'LibraryCategoryCard'
};
</script>

<style lang="less" src="./LibraryCategoryCard.less"></style>
