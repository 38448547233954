<template>
  <v-img
    :lazy-src="categoryImage"
    :src="categoryImage"
    :alt="'alternativeText'"
    :eager="true"
  ></v-img>
</template>

<script>
import LoggerFactory from '@/services/utils/LoggerFactory';
const logger = LoggerFactory.getLogger('CategoryInfoImage.vue');

export default {
  name: 'CategoryInfoImage',
  components: {},
  props: {
    category: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      categoryImage: ''
    };
  },
  computed: {
    categoryImageName() {
      const categoryImageName = this.$store.getters[
        'LibraryStore/geCategoryImageName'
      ](this.category);
      return categoryImageName;
    }
  },
  watch: {
    async categoryImageName(newCategoryImageName) {
      await this.getImage(newCategoryImageName);
    }
  },
  async mounted() {
    await this.getImage(this.categoryImageName);
  },
  methods: {
    async getImage(categoryImageName) {
      try {
        if (categoryImageName) {
          const module = await import(
            `@/assets/images/webp-images/${categoryImageName}`
          );

          this.categoryImage = module?.default || '';
          return;
        }
        return '';
      } catch (error) {
        logger.error(`Get error on load category image error: ${error}`);
      }
      return '';
    }
  }
};
</script>

<style lang="less"></style>
