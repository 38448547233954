import BrandsEnum from '@shared/enums/BrandsEnum.mjs';
import CustomCategoriesEnum from '@shared/enums/CustomCategoriesEnum';

export default {
  [BrandsEnum.FFA]: [
    { name: CustomCategoriesEnum.NEW, icon: 'ico-new' },
    { name: 'Children', icon: 'ico-children' },
    { name: 'Short Stories', icon: 'ico-short-stories', shortName: 'Short' },
    { name: 'Novels', icon: 'ico-novels' },
    { name: 'Drama', icon: 'ico-drama' },
    { name: 'Verse', icon: 'ico-verse' },
    { name: 'Ideas', icon: 'ico-ideas' },
    { name: 'History', icon: 'ico-history' },
    { name: 'Science', icon: 'ico-science' },
    { name: 'Religion', icon: 'ico-religion' }
  ],
  [BrandsEnum.OCEAN]: [
    { name: 'Hindu', icon: 'ico-hindu' },
    { name: 'Zoroastrian', icon: 'ico-zoroastrian' },
    { name: 'Judaism', icon: 'ico-judaism' },
    { name: 'Jainism', icon: 'ico-jainism' },
    { name: 'Confucian', icon: 'ico-confucian' },
    { name: 'Buddhist', icon: 'ico-buddhist' },
    { name: 'Tao', icon: 'ico-tao' },
    { name: 'Christian', icon: 'ico-christian' },
    { name: 'Islam', icon: 'ico-islam' },
    { name: 'Bahá’í', icon: 'ico-bahai' }
  ]
};
