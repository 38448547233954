<template>
  <div
    :style="{ backgroundColor: cardColor }"
    class="category-author-card d-flex flex-row flex-grow-0  py-2 px-3"
  >
    <div v-if="author" class="category-author mr-1">
      <AuthorLinkControl
        class="category-quote-author-link"
        :publication="publication"
        is-link
      />
    </div>
    <div v-if="author" class="category-number">{{ number }}</div>
    <div v-else class="author-amount">+ {{ number }}</div>
  </div>
</template>

<script>
import AuthorLinkControl from '@/components/controls/AuthorLinkControl/AuthorLinkControl.vue';
export default {
  name: 'CategoryAuthorCard',
  components: { AuthorLinkControl },
  props: {
    author: {
      type: String,
      required: false
    },
    authorSlug: {
      type: String,
      required: false
    },
    number: {
      type: Number,
      required: false
    },
    cardColor: String
  },
  data() {
    return {};
  },
  computed: {
    publication() {
      return {
        author: this.author,
        authorList: [
          {
            name: this.author,
            slug: this.authorSlug
          }
        ]
      };
    }
  },
  methods: {}
};
</script>

<style lang="less">
.category-author-card {
  min-height: 40px;
  background-color: #f2f1ee;
  border-radius: 4px;

  .category-author {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #0f172a;

    .category-quote-author-link {
      a {
        text-decoration: none;
        color: var(--primary-color);
      }
    }
    .category-quote-author-link:hover {
      text-decoration: underline;
      text-decoration-color: var(--primary-color);
    }
  }

  .category-number {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: var(--gray-400, #94a3b8);
  }

  .author-amount {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #0f172a;
  }
}
</style>
