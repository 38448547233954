<template>
  <BaseIcon
    class="category-icon"
    :stripe-url="stripeUrl"
    :icon-name="iconName"
  />
</template>

<script>
import BrandsEnum from '@shared/enums/BrandsEnum.mjs';
import BaseIcon from '@/components/base/BaseIcon/BaseIcon.vue';

import CATEGORIES from '@/enums/CategoryFilterIconConfig';
import CustomCategoriesEnum from '@shared/enums/CustomCategoriesEnum';

import spriteReligions from '@/assets/images/svg-images/icons/religions/sprite-religions.svg';
import spriteCategories from '@/assets/images/svg-images/icons/categories/sprite-categories.svg';

export default {
  name: 'CategoryIcon',
  components: { BaseIcon },
  props: {
    category: String
  },
  data() {
    const brand = this.$store.getters['ContextStore/brand'];

    return {
      stripeUrl: brand === BrandsEnum.OCEAN ? spriteReligions : spriteCategories
    };
  },
  computed: {
    iconName() {
      let cat = this.category?.trim()?.toLowerCase();
      if (cat === CustomCategoriesEnum.SUGGESTED_BOOK.toLowerCase()) {
        cat = CustomCategoriesEnum.NEW;
      }
      let config;
      Object.keys(CATEGORIES).forEach(brand => {
        const brandConfig = CATEGORIES[brand];
        if (!config) {
          config = brandConfig.find(cConf => cConf.name?.toLowerCase() === cat);
        }
      });
      return config?.icon || '';
    }
  }
};
</script>

<style lang="less" src="./CategoryIcon.less"></style>
